import React from "react";
import {
  ChartBarIcon,
  HomeIcon,
  MapIcon,
  QueueListIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
const Home = React.lazy(() =>
  import(/* webpackChunkName: "home" */ "@pages/Home")
);
const MapExamples = React.lazy(() =>
  import(/* webpackChunkName: "map-examples" */ "@pages/MapExamples")
);
const ChartExamples = React.lazy(() =>
  import(/* webpackChunkName: "chart-examples" */ "@pages/ChartExamples")
);
const TableExamples = React.lazy(() =>
  import(/* webpackChunkName: "table-examples" */ "@pages/TableExamples")
);
const FrequentElements = React.lazy(() =>
  import(/* webpackChunkName: "frequent-elements" */ "@pages/FrequentElements")
);

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
    hidden: false,
  },
  {
    name: "graphs",
    href: "/charts",
    Icon: ChartBarIcon,
    component: ChartExamples,
    hidden: false,
  },
  {
    name: "tables",
    href: "/tables",
    Icon: TableCellsIcon,
    component: TableExamples,
    hidden: false,
  },
  {
    name: "maps",
    href: "/maps",
    Icon: MapIcon,
    component: MapExamples,
    hidden: false,
  },
  {
    name: "components",
    href: "/components",
    Icon: QueueListIcon,
    component: FrequentElements,
    hidden: false,
  },
];

export default routes;
